import {
  AppBar,
  Box,
  IconButton,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useState } from "react";

interface INavBarLink {
  to: string;
  title: string;
  variant?: "h6";
  className?: string;
  end?: boolean;
}

const NavBarLink = styled(
  ({ to, title, variant, className, end }: INavBarLink) => {
    return (
      <NavLink
        to={to}
        className={({ isActive }) => `${className} ${isActive ? "active" : ""}`}
        end={end}
      >
        <Typography variant={variant} alignSelf="flex-end">
          {title}
        </Typography>
      </NavLink>
    );
  }
)(
  ({ theme }) => `
  display: inline-flex;
  color: ${theme.palette.common.white};
  padding: 5px 10px;
  text-decoration: none;

  & > * {
    border-bottom: 2px solid ${theme.palette.primary.main};
  }

  &.active > * {
    border-bottom: 2px solid ${theme.palette.secondary.main};
  }
`
);

const NavBar = () => {
  const [linkedinHover, setLinkedinHover] = useState(false);
  return (
    <AppBar position="sticky">
      <Toolbar>
        <Box display="flex" flexGrow={1}>
          <NavBarLink to="/" title="Nathaniel Cotton" variant="h6" end />
          <NavBarLink to="/experience" title="Experience" />
          <NavBarLink to="/projects" title="Projects" />
          <NavBarLink to="/resume" title="Resume" />
        </Box>
        <Box>
          <a
            href="https://www.linkedin.com/in/nathanielecotton"
            target="_blank"
            rel="noreferrer"
          >
            <IconButton
              color="info"
              onMouseEnter={() => setLinkedinHover(true)}
              onMouseLeave={() => setLinkedinHover(false)}
            >
              <LinkedInIcon htmlColor={linkedinHover ? "#0096FF" : "#FFF"} />
            </IconButton>
          </a>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
