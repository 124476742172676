export interface IProject {
  name: string;
  shortDescription: string | string[];
  description: string | string[];
  company?: string;
  languages?: string[];
  frameworks?: string[];
  tools?: string[];
}

export const Projects = [
  {
    name: "Data Discovery",
    shortDescription:
      "In conjunction with a UX designer and a data scientist, I built out an internal tool to index a diverse set of datasets stored in an Azure Data Lake instance, to provide data discoverability to the greater organization.",
    description: "",
    company: "Anthology",
    languages: ["C#", "Javascript", "Python", "SQL"],
    frameworks: ["React.js", "ASP.Net Core", "flask"],
    tools: [
      "Azure Functions",
      "Azure Storage Account",
      "Azure SQL Database",
      "Azure Search",
      "Azure Data Lake",
      "Azure Key Vault",
      "Terraform",
      "Azure Pipelines",
      "Docker",
      "Kubernetes",
      "npm",
      "GIT",
      "VSCode",
    ],
  },
  {
    name: "Insight Support",
    shortDescription:
      "I developed an internal tool that provides controlled access to the Insight product for customer support personnel to be able to assist institutions with any technical difficulties they might have.",
    description: "",
    company: "Anthology",
    languages: ["C#", "Javascript"],
    frameworks: ["React.js", "ASP.Net Core"],
    tools: [
      "Azure Functions",
      "Azure Storage Account",
      "Azure Key Vault",
      "Terraform",
      "Azure Pipelines",
      "npm",
      "GIT",
      "VSCode",
    ],
  },
  {
    name: "Academic Economics",
    shortDescription:
      "Alongside a small team of software engineers, I developed data pipelines that run a series of calculations over institution financial data to provide information on the impact of financial decisions.",
    description: "",
    company: "Anthology",
    languages: ["C#", "Javascript", "SQL"],
    frameworks: ["React.js", "ASP.Net Core"],
    tools: [
      "Azure Functions",
      "Azure Storage Account",
      "Azure SQL Database",
      "Azure Data Lake",
      "Azure Data Factory",
      "Azure Key Vault",
      "PowerBI",
      "Terraform",
      "Azure Pipelines",
      "npm",
      "GIT",
      "VSCode",
    ],
  },
  {
    name: "Insight",
    shortDescription:
      "In collaboration with software engineers, UX designers, and data scientists I worked to enhance and maintain a dynamic data exploratory tool to provide a cohesive picture of an institution’s data, along with data analytic integrations.",
    description: "",
    company: "Anthology",
    languages: ["C#", "Javascript", "SQL"],
    frameworks: ["React.js", "ASP.Net Core"],
    tools: [
      "Azure App Services",
      "Azure SQL Database",
      "Azure Key Vault",
      "Kubernetes",
      "ElasticSearch",
      "Azure Pipelines",
      "npm",
      "GIT",
      "VSCode",
    ],
  },
  {
    name: "Risk Score Model",
    shortDescription:
      "I assisted several data scientists to build out the infrastructure needed to pull data from different company products into a cohesive data model.  Which was subsequently used to build a predictive model. Then setting the model to run on a continual basis, and integrate results with the customer-facing application.",
    description: "",
    company: "Anthology",
    languages: ["Python", "R"],
    tools: [
      "Azure Functions",
      "Azure Container Registry",
      "Azure Container Service",
      "Azure Data Lake",
      "Azure Storage Account",
      "Azure Key Vault",
      "Docker",
      "Terraform",
      "Azure Pipelines",
      "GIT",
      "VSCode",
      "RStudio",
    ],
  },
  {
    name: "Beacon",
    shortDescription:
      "In coordination with a small team of software engineers, I worked to modernize an early alerting system product through the usage of the strangler pattern.",
    description: "",
    company: "Anthology",
    languages: ["C#", "Javascript"],
    frameworks: ["React.js", "ASP.Net Core", ".Net Framework"],
    tools: [
      "Azure App Services",
      "Azure Functions",
      "Azure SQL Database",
      "Azure Search",
      "Azure Data Factory",
      "Azure Key Vault",
      "Azure Analysis Services",
      "Terraform",
      "Azure Pipelines",
      "npm",
      "yarn",
      "GIT",
      "VSCode",
      "Visual Studio",
    ],
  },
  {
    name: "Attribute Fusion",
    shortDescription:
      "A small team of software engineers and myself worked to migrate a deprecated demographic matching system over to an efficient algorithm that executes across a distributed set of databases deployed on low-cost cloud architecture. Then collaborated with a team of data scientists to further enhance the algorithm.",
    description: "",
    company: "Trove Predictive Data science",
    languages: ["Java", "Python", "R", "SQL"],
    frameworks: ["Spring Boot"],
    tools: [
      "Docker",
      "PostgreSQL",
      "gRPC",
      "S3",
      "NGINX",
      "Jenkins",
      "GIT",
      "IntelliJ",
      "RStudio",
    ],
  },
  {
    name: "Vegetation Management",
    shortDescription:
      "I worked on a small team of software engineers and data scientists to build out pipelines to ingest LiDAR data and supplemental asset data from energy companies to classify high-risk areas caused by vegetation growth.",
    description: "",
    company: "Trove Predictive Data science",
    languages: ["Java", "SQL"],
    frameworks: ["Spring Boot", "JUnit"],
    tools: ["Docker", "PostgreSQL", "NGINX", "Jenkins", "GIT", "IntelliJ"],
  },
  {
    name: "Compatibility Units (CU)",
    shortDescription:
      "In coordination with data scientists and a frontend engineer, I worked to collect requirements to design, develop, and deliver on a greenfield project. The project was focused on managing assets within the energy sector to make decisions on where repairs needed to be made and where new construction should take place.",
    description: "",
    company: "Trove Predictive Data Science",
    languages: ["Java", "SQL"],
    frameworks: ["Spring Boot", "JUnit"],
    tools: ["Docker", "PostgreSQL", "NGINX", "Jenkins", "GIT", "IntelliJ"],
  },
  {
    name: "Executer Service",
    shortDescription:
      "I formalized and executed a development plan to create a dynamic execution service primarily used for the execution of small to medium scale analytics.",
    description: "",
    company: "Trove Predictive Data Science",
    languages: ["Java", "Python", "R", "SQL"],
    frameworks: ["Spring Boot", "JUnit"],
    tools: [
      "Docker",
      "PostgreSQL",
      "gRPC",
      "NGINX",
      "Jenkins",
      "GIT",
      "IntelliJ",
    ],
  },
  {
    name: "Data Pipeline",
    shortDescription:
      "I worked to create a new dynamic ingestion pipeline that had plugable modules for targeting particular underlying data stores used by Trove.",
    description: "",
    company: "Trove Predictive Data Science",
    languages: ["Java", "SQL"],
    frameworks: ["Spring Boot", "JUnit"],
    tools: [
      "Docker",
      "PostgreSQL",
      "gRPC",
      "NGINX",
      "Jenkins",
      "GIT",
      "IntelliJ",
    ],
  },
  {
    name: "Trove Platform",
    shortDescription:
      "Along with a team of software engineers I developed, tested, and delivered new features on the Trove Platform project, which was focused on modular analytic plugins as well as data cohesion across a diverse set of underlying data stores.",
    description: "",
    company: "Trove Predictive Data Science",
    languages: ["Java", "SQL"],
    frameworks: ["Spring Boot", "Spring Framework", "Spring Data", "JUnit"],
    tools: ["PostgreSQL", "Cassandra", "NGINX", "Jenkins", "GIT", "IntelliJ"],
  },
  {
    name: "Proforma",
    shortDescription:
      "I worked to improve an existing project used to help manage the flow of requested export items, and generation of documentation for shipping.",
    description: "",
    company: "Moog Inc.",
    languages: ["Java", "Javascript"],
    frameworks: ["Struts2", "MyBatis", "Bootstrap", "jQuery"],
    tools: ["DB2", "GIT", "Eclipse"],
  },
  {
    name: "Tools",
    shortDescription:
      "I worked to gather requirements from engineering team leads to build out a development plan for an application to manage required tools for item fabrication.",
    description: "",
    company: "Moog Inc.",
    languages: ["C#", "Javascript"],
    frameworks: [".Net Framework", "Entity Framework", "Bootstrap", "jQuery"],
    tools: ["SQL Server", "GIT", "Visual Studio"],
  },
  {
    name: "Merger & Acquisition Testing Suite",
    shortDescription:
      "I worked with a team of test engineers to build out an integration testing suite to verify the ingestion of merger and acquisition documents.",
    description: "",
    company: "Thomson Reuters",
    languages: ["Java", "SQL"],
    frameworks: ["Spring Framework", "TestNG"],
    tools: ["Jenkins", "SQL Server", "GIT", "Eclipse"],
  },
  {
    name: "East Aurora Family Practice Website",
    shortDescription:
      "I worked with a local family practice to replace their existing website with a modernized version.",
    description: "",
    languages: ["Javascript"],
    frameworks: ["React.js"],
    tools: ["Docker", "NGINX", "npm", "GIT", "VSCode"],
  },
] as IProject[];
