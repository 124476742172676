import { Route, Routes } from "react-router";
import ProjectPage from "./ProjectPage";
import ProjectsPage from "./ProjectsPage";

const ProjectRoutes = () => (
  <Routes>
    <Route path="/{project}" element={<ProjectPage />} />
    <Route path="/" element={<ProjectsPage />} />
  </Routes>
);

export default ProjectRoutes;
