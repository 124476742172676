import { Card, Container, Fab, Stack } from "@mui/material";
import { useState } from "react";
// import { Document, Page } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import DownloadIcon from "@mui/icons-material/Download";

const ResumePage = () => {
  const [numPages, setNumPages] = useState<number>();
  return (
    <Container
      maxWidth="xl"
      style={{ marginTop: "10px", marginBottom: "10px" }}
    >
      <Document
        file="/docs/Resume.pdf"
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        <Stack spacing={3}>
          {numPages &&
            Array.from(Array(numPages).keys()).map((i) => (
              <div key={i}>
                <Card
                  elevation={4}
                  style={{
                    width: "1000px",
                    margin: "0 auto",
                  }}
                  square
                >
                  <Page pageNumber={i + 1} width={1000} />
                </Card>
              </div>
            ))}
        </Stack>
      </Document>
      <a
        href="/docs/Resume.pdf"
        download="Nathaniel_Cotton_Resume.pdf"
        style={{
          position: "fixed",
          bottom: "50px",
          right: "50px",
          color: "white",
        }}
      >
        <Fab color="primary" aria-label="Download">
          <DownloadIcon />
        </Fab>
      </a>
    </Container>
  );
};

export default ResumePage;
