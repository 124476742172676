import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/system";
import { useState } from "react";
import { IProject, Projects } from "./Projects";

const StyleChip = styled(Chip)`
  margin-right: 3px;
  margin-bottom: 3px;
`;

const ProjectCard = ({
  name,
  company,
  shortDescription,
  languages,
  frameworks,
  tools,
}: IProject) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <Card
      square
      elevation={isHover ? 9 : 3}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{ height: "100%" }}
    >
      <CardHeader
        title={
          <>
            <Typography variant="h4" variantMapping={{ h4: "h2" }}>
              {name}
            </Typography>
            <Typography variant="h6" variantMapping={{ h6: "h3" }}>
              {company}
            </Typography>
          </>
        }
      />
      <CardContent>
        <Stack>
          <Box flexGrow={1} marginBottom={2}>
            <Typography>{shortDescription}</Typography>
          </Box>
          {languages && (
            <Box>
              <Typography fontWeight={600}>Languages</Typography>
              {languages.map((x, i) => (
                <StyleChip key={i} label={x} />
              ))}
            </Box>
          )}
          {frameworks && (
            <Box>
              <Typography fontWeight={600}>Frameworks</Typography>
              {frameworks.map((x, i) => (
                <StyleChip key={i} label={x} />
              ))}
            </Box>
          )}
          {tools && (
            <Box>
              <Typography fontWeight={600}>Tools</Typography>
              {tools.map((x, i) => (
                <StyleChip key={i} label={x} />
              ))}
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

const ProjectsPage = () => {
  const [filterTags, setFilterTags] = useState<string[]>([]);
  const tags = Object.entries(
    Projects.flatMap((x) => [
      ...(x.frameworks ?? []),
      ...(x.languages ?? []),
      ...(x.tools ?? []),
    ]).reduce(function (acc: any, curr) {
      return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
    }, {})
    //@ts-ignore
  ).sort((a, b) => a[0].localeCompare(b[0]));

  const displayProjects =
    filterTags.length === 0
      ? Projects
      : Projects.filter((project) => {
          const tags = [
            ...(project.frameworks ?? []),
            ...(project.languages ?? []),
            ...(project.tools ?? []),
          ];
          return filterTags.every((v) => tags.includes(v));
        });
  return (
    <div
      style={{
        paddingTop: "60px",
        paddingBottom: "60px",
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h1">Projects</Typography>
              <div>
                {tags.map((x, i) => (
                  <Chip
                    key={i}
                    label={`${x[0]} (${x[1]})`}
                    variant={
                      filterTags.findIndex((y) => y === x[0]) === -1
                        ? "outlined"
                        : "filled"
                    }
                    color={
                      filterTags.findIndex((y) => y === x[0]) === -1
                        ? undefined
                        : "secondary"
                    }
                    style={{ marginRight: "4px", marginBottom: "4px" }}
                    onClick={() => {
                      if (filterTags.findIndex((y) => y === x[0]) === -1) {
                        // add
                        setFilterTags([...filterTags, x[0]]);
                      } else {
                        // remove
                        setFilterTags(filterTags.filter((y) => y !== x[0]));
                      }
                    }}
                  />
                ))}
              </div>
            </Stack>
          </Grid>
          {Projects.map((x, i) => (
            <Zoom
              key={i}
              in={displayProjects.findIndex((y) => y.name === x.name) !== -1}
              timeout={250}
              unmountOnExit
            >
              <Grid item xs={12} sm={12} md={6}>
                <ProjectCard {...x} />
              </Grid>
            </Zoom>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default ProjectsPage;
