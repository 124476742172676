import { Grid, Stack, Typography } from "@mui/material";
import { Parallax } from "react-parallax";
import ReactTypingEffect from "react-typing-effect";

const Introduction = () => (
  <Parallax
    blur={2.5}
    bgImage="/images/homeBackground.webp"
    bgImageAlt="Homepage background"
    strength={200}
  >
    <Stack
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        alt="Nathaniel Cotton"
        src="/images/profile.webp"
        style={{
          width: "15%",
          height: "auto",
          display: "inline-block",
        }}
      />
      <div>
        <ReactTypingEffect
          text={["Nathaniel Cotton"]}
          eraseDelay={9999999}
          eraseSpeed={500}
          //@ts-ignore
          cursorRenderer={(cursor) => (
            <Typography
              variant="h3"
              variantMapping={{ h3: "h1" }}
              color="#d47800"
            >
              {cursor}
            </Typography>
          )}
          //@ts-ignore
          displayTextRenderer={(text, i) => (
            <Typography
              variant="h3"
              variantMapping={{ h3: "h1" }}
              color="#d47800"
            >
              {text}
            </Typography>
          )}
        />
      </div>
      <div>
        <ReactTypingEffect
          text={[
            "Full-stack Developer",
            "Backend Developer",
            "Frontend Developer",
            "Data Engineer",
          ]}
          //@ts-ignore
          cursorRenderer={(cursor) => (
            <Typography
              variant="h4"
              variantMapping={{ h4: "h2" }}
              color="#fdba00"
            >
              {cursor}
            </Typography>
          )}
          //@ts-ignore
          displayTextRenderer={(text, i) => (
            <Typography
              variant="h4"
              variantMapping={{ h4: "h2" }}
              color="#fdba00"
            >
              {text}
            </Typography>
          )}
        />
      </div>
    </Stack>
  </Parallax>
);

const HomePage = () => (
  <Grid container direction="column">
    <Grid item>
      <Introduction />
    </Grid>
  </Grid>
);

export default HomePage;
