import React from "react";
import NavBar from "./components/NavBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import ExperiencePage from "./pages/experience/ExperiencePage";
import ResumePage from "./pages/resume/ResumePage";
import ProjectRoutes from "./pages/projects/ProjectsRoutes";

function App() {
  return (
    <div>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/experience" element={<ExperiencePage />} />
          <Route path="/projects" element={<ProjectRoutes />} />
          <Route path="/resume" element={<ResumePage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
