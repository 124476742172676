import { Container, Typography } from "@mui/material";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
import StarIcon from "@mui/icons-material/Star";

const ExperiencePage = () => (
  <Container maxWidth="xl">
    {/* @ts-ignore */}
    <VerticalTimeline lineColor="#000">
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        dateClassName="timeline-date"
        contentStyle={{ background: "#000", color: "#fff" }}
        contentArrowStyle={{ borderRight: "7px solid #000" }}
        date="January 2020 - present"
        iconStyle={{
          background: "rgb(33, 150, 243)",
          color: "#fff",
          boxShadow:
            "0 0 0 4px #000, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%)",
        }}
        icon={<WorkIcon />}
      >
        <Typography variant="h5" variantMapping={{ h5: "h3" }}>
          Anthology
        </Typography>
        <Typography variant="h6" variantMapping={{ h6: "h4" }}>
          Senior Software Developer
        </Typography>
        <Typography>
          I collaborate with several teams to build out data-driven solutions on
          the Microsoft technology stack designed to unveil insights to improve
          student experiences and success.
        </Typography>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        dateClassName="timeline-date"
        date="April 2017 - January 2020"
        iconStyle={{
          background: "rgb(33, 150, 243)",
          color: "#fff",
          boxShadow:
            "0 0 0 4px #000, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%)",
        }}
        contentStyle={{ background: "#000", color: "#fff" }}
        contentArrowStyle={{ borderRight: "7px solid #000" }}
        icon={<WorkIcon />}
      >
        <Typography variant="h5" variantMapping={{ h5: "h3" }}>
          Trove Predictive Data Sciences
        </Typography>
        <Typography variant="h6" variantMapping={{ h6: "h4" }}>
          Software Engineer III
        </Typography>
        <Typography>
          I worked with a team of software engineers and data scientists to
          tackle several unique problems ranging from demographic data matching
          to vegetation management. Solutions utilized a microservice
          architecture for flexibility and scale built using docker and deployed
          on AWS.
        </Typography>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        dateClassName="timeline-date"
        date="January 2016 - May 2016"
        contentStyle={{ background: "#000", color: "#fff" }}
        contentArrowStyle={{ borderRight: "7px solid #000" }}
        iconStyle={{
          background: "rgb(233, 30, 99)",
          color: "#fff",
          boxShadow:
            "0 0 0 4px #000, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%)",
        }}
        icon={<SchoolIcon />}
      >
        <Typography variant="h5" variantMapping={{ h5: "h3" }}>
          Rochester Institute of Technology
        </Typography>
        <Typography variant="h6" variantMapping={{ h6: "h4" }}>
          Student Lab Instructor
        </Typography>
        <Typography>
          Working with a team of SLIs to assist the professor in teaching the
          lab portion of the course, as well as grading students’ final
          submissions.
        </Typography>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        date="June 2014 - January 2015, May 2015 - August 2015"
        dateClassName="timeline-date"
        contentStyle={{ background: "#000", color: "#fff" }}
        contentArrowStyle={{ borderRight: "7px solid #000" }}
        iconStyle={{
          background: "rgb(233, 30, 99)",
          color: "#fff",
          boxShadow:
            "0 0 0 4px #000, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%)",
        }}
        icon={<SchoolIcon />}
      >
        <Typography variant="h5" variantMapping={{ h5: "h3" }}>
          Moog Inc.
        </Typography>
        <Typography variant="h6" variantMapping={{ h6: "h4" }}>
          Software Developer (Co-op)
        </Typography>
        <Typography>
          Worked on a team and individually on several web development projects
          in both Java and C# to improve efficiency, and modernize the processes
          that engineers use to help build, and maintain Moog-related products.
        </Typography>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        dateClassName="timeline-date"
        date="August 2013 - January 2014"
        contentStyle={{ background: "#000", color: "#fff" }}
        contentArrowStyle={{ borderRight: "7px solid #000" }}
        iconStyle={{
          background: "rgb(233, 30, 99)",
          color: "#fff",
          boxShadow:
            "0 0 0 4px #000, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%)",
        }}
        icon={<SchoolIcon />}
      >
        <Typography variant="h5" variantMapping={{ h5: "h3" }}>
          Thomson Reuters
        </Typography>
        <Typography variant="h6" variantMapping={{ h6: "h4" }}>
          Software Test Engineer (Co-op)
        </Typography>
        <Typography>
          Worked on developing and maintaining a Java-based test suite, which
          utilized TestNG and the Spring framework, to validate the
          functionality of a new legal pathway. Responsibilities included
          writing test classes, presenting information, and participating in
          daily stand ups as well as participating in presentations.
        </Typography>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        dateClassName="timeline-date"
        date="September 2011 - December 2016"
        contentStyle={{ background: "#000", color: "#fff" }}
        contentArrowStyle={{ borderRight: "7px solid #000" }}
        iconStyle={{
          background: "rgb(233, 30, 99)",
          color: "#fff",
          boxShadow:
            "0 0 0 4px #000, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%)",
        }}
        icon={<SchoolIcon />}
      >
        <Typography variant="h5" variantMapping={{ h5: "h3" }}>
          Rochester Institute of Technology
        </Typography>
        <Typography variant="h6" variantMapping={{ h6: "h4" }}>
          Bachelor and Masters Degree
        </Typography>
        <Typography>Major: Computer Science</Typography>
        <Typography>Minor: Economics</Typography>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
        icon={<StarIcon />}
      />
    </VerticalTimeline>
  </Container>
);

export default ExperiencePage;
